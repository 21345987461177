<template>
  <v-container fluid>
    <v-row class="ma-3" wrap>
      <!-- nome -->
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-text-field
          label="Nome do Setor"
          ref="nome"
          placeholder="Ex.: Administrativo"
          required
          outlined
          dense
          v-model="setor.nome"
          data-vv-as="nome"
          persistent-hint
          :hint="errors.first('avd-setor.nome')"
          :error="errors.collect('avd-setor.nome').length ? true : false"
          v-validate="'required'"
          data-vv-validate-on="change"
          data-vv-scope="avd-setor"
          id="nome"
          name="nome"
        ></v-text-field>
      </v-col>
      <!-- Responsavel -->
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-select
          label="Responsável"
          :items="responsaveis"
          item-text="nome"
          item-value="id"
          placeholder="Selecione um responsável"
          outlined
          dense
          required
          v-model="setor.id_responsavel"
          data-vv-as="responsável"
          persistent-hint
          :hint="errors.first('avd-setor.responsavel')"
          :error="errors.collect('avd-setor.responsavel').length ? true : false"
          v-validate="'required'"
          data-vv-validate-on="change"
          data-vv-scope="avd-setor"
          id="responsavel"
          name="responsavel"
          :loading="loadings.loadingResponsavel"
        ></v-select>
      </v-col>
      <!-- Cliente -->
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-select
          label="Cliente"
          :items="clientes"
          item-text="nome"
          item-value="id"
          placeholder="Selecione um cliente"
          outlined
          dense
          required
          v-model="setor.id_cliente"
          :loading="loadings.loadingCliente"
        ></v-select>
      </v-col>
      <!-- Filial -->
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-select
          label="Filial"
          :items="filiais"
          item-text="nome"
          item-value="id"
          placeholder="Selecione uma filial"
          outlined
          dense
          required
          v-model="setor.id_filial"
          :loading="loadings.loadingFilial"
        ></v-select>
      </v-col>
      <!-- TipoSetores -->
      <v-col cols="12">
        <v-select
          label="Tipo setores"
          :items="tiposSetores"
          item-text="descricao"
          item-value="id"
          placeholder="Selecione um tipo de setor"
          outlined
          dense
          required
          v-model="setor.id_tipo"
          data-vv-as="tipo de setor"
          persistent-hint
          :hint="errors.first('avd-setor.tipoSetores')"
          :error="errors.collect('avd-setor.tipoSetores').length ? true : false"
          v-validate="'required'"
          data-vv-validate-on="change"
          data-vv-scope="avd-setor"
          id="tipoSetores"
          name="tipoSetores"
          :loading="loadings.loadingTipo"
        ></v-select>
      </v-col>
      <!-- descricao -->
      <v-col cols="12" sm="12" md="12">
        <v-textarea
          label="Descrição"
          ref="descricao"
          placeholder="Ex.: Uma parte essencial da empresa."
          outlined
          dense
          v-model="setor.descricao"
          data-vv-as="descrição"
          persistent-hint
          :hint="errors.first('avd-setor.descricao')"
          :error="errors.collect('avd-setor.descricao').length ? true : false"
          v-validate="'required'"
          data-vv-validate-on="change|blur|input"
          data-vv-scope="avd-setor"
          id="descricao"
          name="descricao"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "FormSetores",
  inject: ["$validator"],
  async created() {
    const dataUsuarios = {
      columns: ["id", "nome"],
      validate: [
        ["status", "A"],
        ["gestor", 1],
      ],
    };
    const dataCliente = {
      columns: ["id", "nome"],
      validate: [["status", "A"]],
    };
    const dataFilial = {
      columns: ["id", "nome"],
      validate: [["status", "A"]],
    };
    const dataTipo = {
      columns: ["id", "descricao"],
    };

    await this.$store.dispatch("usuarios/getDataByColumn", dataUsuarios);
    this.loadings.loadingResponsavel = false;

    await this.$store.dispatch("clientes/getAllDatabyColumns", dataCliente);
    this.loadings.loadingCliente = false;

    await this.$store.dispatch("filiais/findByColumns", dataFilial);
    this.loadings.loadingFilial = false;

    await this.$store.dispatch("tiposSetores/findByColumns", dataTipo);
    this.loadings.loadingTipo = false;
  },
  data() {
    return {
      loadings: {
        loadingResponsavel: true,
        loadingCliente: true,
        loadingFilial: true,
        loadingTipo: true,
      },
    };
  },
  computed: {
    setor: {
      get() {
        return this.$store.getters["avdSetores/getSetor"];
      },
    },
    filiais: {
      get() {
        return this.$store.getters["filiais/getFiliais"];
      },
    },
    clientes: {
      get() {
        return this.$store.getters["clientes/getClientes"];
      },
    },
    responsaveis: {
      get() {
        return this.$store.getters["usuarios/getResponsaveis"];
      },
    },
    tiposSetores: {
      get() {
        return this.$store.getters["tiposSetores/getTiposSetores"];
      },
    },
  },
};
</script>

<style></style>
